import { ethers } from 'ethers'

import memoize from 'lodash/memoize.js'
import ABI from '../ABI.js'

export default class RewardDistributor {
  constructor (provider, address) {
    this.contract = new ethers.Contract(address, ABI.RewardDistributor, provider)

    this.epochCurrent = memoize(this.epochCurrent)
  }

  // Read

  async epochCurrent () {
    return await this.contract.epochCurrent()
  }

  async getEpoch (timestamp) {
    return await this.contract.getEpoch(timestamp)
  }

  async getTimestamp (epoch) {
    return await this.contract.getTimestamp(epoch)
  }

  async getUnclaimedReward (account, epochTarget) {
    return await this.contract.getUnclaimedReward(account, epochTarget)
  }

  async schedules (index) {
    try {
      const schedule = await this.contract.schedules(index)

      return {
        amount: schedule[0],
        epochStart: schedule[1],
        epochNum: schedule[2]
      }
    } catch {
      return null
    }
  }

  // Write

  async claimReward (signer, epochTarget) {
    const contract = this.contract.connect(signer)
    const tx = await contract.claimReward(signer.address, epochTarget)

    return await tx.wait()
  }
}
