/* global Elm, IntersectionObserver */

import SDK from './SDK.js'
import './customElements/DynamicText.js'
import {
  claimReward,
  connectWallet,
  disconnectWallet,
  getAvailableRewards,
  getStakingValues,
  getQodaBalance,
  getQodaStakedBalance,
  getUniV2Balance,
  getUniV2StakedBalance,
  stakeQoda,
  unstakeQoda,
  stakeUniV2,
  unstakeUniV2
} from './actions.js'

// MAIN

window.addEventListener('DOMContentLoaded', async () => {
  const sdk = new SDK()

  await sdk.init(sdk.buildEnv, 'arbitrum-one')
  // sdk.setLogLevel('DEBUG')

  window.sdk = sdk

  sdk.logger.debug({ sdk })

  const app = Elm.Main.init({
    node: document.getElementById('app')
  })

  sdk.ports = app.ports

  app.ports.send.subscribe(async (message) => {
    sdk.logger.debug('message', message)

    let tag = message?.tag
    let result = null

    if (tag === 'loaded') {
      setupScrollAnchor('.page__scroll-anchor', app.ports.receivePageScrollAnchorIsIntersecting.send)
    } else if (tag === 'connectWallet') {
      result = await connectWallet(sdk)()

      app.ports.receiveWalletAddress.send(result)
    } else if (tag === 'disconnectWallet') {
      await disconnectWallet(sdk)()
    } else if (tag === 'getStakingValues') {
      result = await getStakingValues(sdk)(message.value)

      app.ports.receiveStakingValues.send(result)
    } else if (tag === 'getAvailableRewards') {
      result = await getAvailableRewards(sdk)(message.value)

      app.ports.receiveAvailableRewards.send(result)
    } else if (tag === 'claimReward') {
      result = await claimReward(sdk)()

      app.ports.receiveClaimRewardResponse.send(result)
    } else if (tag === 'getQodaBalance') {
      result = await getQodaBalance(sdk)(message.value)

      app.ports.receiveQodaBalance.send(result)
    } else if (tag === 'getQodaStakedBalance') {
      result = await getQodaStakedBalance(sdk)(message.value)

      app.ports.receiveQodaStakedBalance.send(result)
    } else if (tag === 'getUniV2Balance') {
      result = await getUniV2Balance(sdk)(message.value)

      app.ports.receiveUniV2Balance.send(result)
    } else if (tag === 'getUniV2StakedBalance') {
      result = await getUniV2StakedBalance(sdk)(message.value)

      app.ports.receiveUniV2StakedBalance.send(result)
    } else if (tag === 'stakeQoda') {
      result = await stakeQoda(sdk)(message.value)

      app.ports.receiveStakeQodaResponse.send(result)
    } else if (tag === 'unstakeQoda') {
      result = await unstakeQoda(sdk)(message.value)

      app.ports.receiveUnstakeQodaResponse.send(result)
    } else if (tag === 'stakeUniV2') {
      result = await stakeUniV2(sdk)(message.value)

      app.ports.receiveStakeUniV2Response.send(result)
    } else if (tag === 'unstakeUniV2') {
      result = await unstakeUniV2(sdk)(message.value)

      app.ports.receiveUnstakeUniV2Response.send(result)
    } else if (tag === 'openDialog') {
      openDialog(message.value)
    } else if (tag === 'closeDialog') {
      closeDialog(message.value)
    } else if (tag === 'logError') {
      sdk.logger.error(message.value)
    } else {
      sdk.logger.error('An unexpected message was received.', message)
      tag = null
    }

    if (tag && result) {
      sdk.logger.debug(tag, result)
    }
  })

  window.addEventListener('dapp:detectedMetaMask', (event) => {
    sdk.logger.debug('dapp:detectedMetaMask', event)

    sdk.setBrowserProvider(event.detail)

    app.ports.receiveHasWallet.send(true)
  })

  window.addEventListener('eip6963:announceProvider', (event) => {
    sdk.logger.debug('eip6963:announceProvider', event)

    if (event.detail?.info?.rdns === 'io.metamask') {
      //
      // N.B. This is not a reliable way to detect MetaMask but it works for now.
      //
      window.dispatchEvent(
        new CustomEvent('dapp:detectedMetaMask', { detail: event.detail })
      )
    }
  })

  window.dispatchEvent(new Event('eip6963:requestProvider'))
})

// DIALOG

function openDialog (id) {
  const dialog = document.getElementById(id)
  dialog.showModal()
}

function closeDialog (id) {
  const dialog = document.getElementById(id)
  dialog.close()
}

//
// NAVIGATION
//
// This is part of how we handle changing the navigation when the user scrolls.
//

function setupScrollAnchor (scrollAnchorClass, callback) {
  const scrollAnchor = document.querySelector(scrollAnchorClass)

  const observer = new IntersectionObserver(([entry]) => {
    callback(entry.isIntersecting)
  })

  observer.observe(scrollAnchor)
}
