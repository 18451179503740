const ERC20 = [
  // Read

  'function allowance(address owner, address spender) view returns (uint256)',
  'function balanceOf(address account) view returns (uint256)',
  'function decimals() view returns (uint8)',
  'function name() view returns (string)',
  'function symbol() view returns (string)',
  'function totalSupply() view returns (uint256)',

  // Write

  'function approve(address spender, uint256 value) returns (bool)'
]

const StakingInfo = '(uint256 amount, uint256 amountVe, uint256 lastUpdateSec)'

const VeQoda = [
  // Events

  'event Stake(address indexed account, bytes32 method, address token, uint256 amount)',
  'event Unstake(address indexed account, bytes32 method, address token, uint256 amount)',

  // Read

  'function SCALE_FACTOR_VE_PER_DAY() view returns (uint256)',
  'function STAKE_LIQUIDITY_POOL() view returns (bytes32)',
  'function STAKE_VANILLA() view returns (bytes32)',
  'function accountVe(address account, uint256 timestamp) view returns (uint256)',
  'function methodInfo(bytes32 method) view returns (address, uint8, uint256, uint256[], uint256[])',
  'function methods() view returns (bytes32[])',
  'function totalVe(uint256 timestamp) view returns (uint256)',
  `function userStakingInfo(address account, bytes32 method) view returns (${StakingInfo})`,

  // Write

  'function stake(address account, bytes32 method, uint256 amount)',
  'function unstake(bytes32 method, uint256 amount)'
]

const RewardDistributor = [
  // Read

  'function epochCurrent() view returns (uint256)',
  'function getEpoch(uint256 timestamp) view returns (uint256)',
  'function getTimestamp(uint256 epoch) view returns (uint256)',
  'function getUnclaimedReward(address account, uint256 epochTarget) view returns (uint256)',
  'function schedules(uint256 index) view returns (uint256, uint256, uint256)',

  // Write

  'function claimReward(address account, uint256 epochTarget)'
]

const UniswapV2Factory = [
  // Read

  'function getPair(address tokenA, address tokenB) view returns (address)'
]

const UniswapV2Pair = [
  // Read

  'function getReserves() view returns (uint112 reserve0, uint112 reserve1, uint32 blockTimestampLast)',
  'function token0() view returns (address)',
  'function token1() view returns (address)'
]

const UniswapV2Router02 = [
  // Read

  'function factory() view returns (address)',
  'function getAmountsOut(uint256 amountIn, address[] path) view returns (uint256[])'
]

const CustomErrors = [
  'error TransferFromZeroAddress()',
  'error BuyFeesTooHigh()',
  'error SellFeesTooHigh()',
  'error ZeroStakeAmount()',
  'error ZeroUnstakeAmount()',
  'error InvalidStakingToken()',
  'error InvalidEffectiveTime()',
  'error DistributorAlreadyExist()',
  'error DistributorNotExist()',
  'error InsufficientBalance()',
  'error TransferDisabled()',
  'error EpochUndefined()',
  'error EpochHasPassed()',
  'error MinEpochNotMet()',
  'error MaxEpochNotMet()',
  'error MinRewardNotMet()',
  'error MinRewardMustExist()',
  'error InvalidExclusionAddress()',
  'error InvalidAutomatedMarketMakerPairs()',
  'error InvalidTokenAddress()',
  'error InvalidVeTokenAddress()',
  'error InvalidAccount()'
]

export default { ERC20, VeQoda, RewardDistributor, UniswapV2Factory, UniswapV2Pair, UniswapV2Router02, CustomErrors }
